@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';

.selectsSection {
  display: flex;
  align-items: center;
  gap: 0 10px;

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
  }

  :global {
    .ant-select {
      color: #000000;
      background-color: white;
      border: 1px solid #d9d9d9;
      border-radius: 8px;
      position: relative;
      overflow: visible;
      min-width: 80px;
    }
    // item selected from dropdown
    .ant-select-selection-item {
      overflow: visible;
      :local {
        .reportCount {
          display: none;
        }
      }
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: transparent;
      border-color: transparent;
      padding: 0 11px;
      @media only screen and (min-width: 400px) and (max-width: 576px) {
        width: auto;
      }
      @media @sm {
        padding: 0 11px;
      }

      :local {
        .currentLabel {
          font-size: 10px;
          margin-left: 4px;
          position: absolute;
          top: -7px;
          left: -8px;
          background: white;
          line-height: 10px;
          padding-inline: 2px;
          font-style: italic;
          @media @sm {
            font-size: 11px;
            position: unset;
            margin-left: 0;
          }
        }
      }
    }

    .ant-select-selection-placeholder {
      color: #fff;
    }
    .ant-select-selection-search-input {
      font-size: 16px;
    }
  }
}
.selectDropdown {
  :global {
    .ant-select-item {
      font-size: 16px;
    }
  }
  .currentLabel {
    font-size: 13px;
    margin-left: 3px;
    font-style: italic;
    font-weight: 400;
    display: inline-block;
  }
}
.seasonIcon {
  display: none;
  margin-right: 4px;
  width: 15px;
  @media @sm {
    display: inline-flex;
  }
}

.seasonNameLine {
  display: flex;
  align-items: center;
  @media @sm {
    margin-right: 10px;
  }
}

.seasonName {
  display: block;
  margin-right: 3px;
}

.selectBarSelectorsText {
  flex: 1;
  padding: 12px;
  text-align: center;
  width: 100%;
  color: #fff;
  &:hover {
    color: #fff;
  }
}

.seasonText {
  padding-left: 4px;
}

.selectLabel {
  display: flex;
  justify-content: space-between;
}
.selectName {
  display: flex;
  align-items: center;
}
.selectImg {
  display: none;
  width: 40px;
  margin-right: 6px;

  @media @sm {
    display: block;
  }
}

.selectImg__mobile {
  display: block;
}

.seasonIcon__mobile {
  display: block;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.selectsSection__blink {
  animation: blink 1s;
  animation-iteration-count: 1;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;