@import (reference) '../../styles/media.less';
@import (reference) '../../styles/mixins.less';
@import (reference) '../../styles/typography.less';

.mainContentWrapper {
  display: flex;
  gap: 12px;
  flex-direction: column;
  transition: height 0.4s ease-in-out;
  overflow: hidden;
  @media @sm {
    padding-bottom: 24px;
    flex-direction: row;
  }
}

.profileCol {
  flex: 1;
  @media @xl {
    flex: unset;
    flex-basis: 26%;
    display: flex;
    flex-direction: column;
  }
}

.dashboardHeader {
  display: flex;
  flex-direction: column-reverse;

  @media @sm {
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
  }
  p {
    .termina();
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    margin-bottom: 5px;
    @media @sm {
      margin-bottom: 1em;
      font-size: 24px;
    }
  }
}

.logOutButton {
  color: #fff;
  font-weight: 400;
  padding-inline: 0;
  @media @sm {
    padding-inline: 15px 7px;
  }
}

.rotateIcon {
  transform: rotate(180deg);
  transition: transform 0.3s;
}
.rotateIcon__180 {
  transform: rotate(0deg);
  transition: transform 0.3s;
}

.headerButtons {
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  @media @sm {
    flex-direction: row;
  }
}

.headerButtonsTop {
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  margin-bottom: 12px;
  @media @sm {
    margin-bottom: 0;
  }
}

.openDashboardButton {
  .textColor(#fff);
  padding-inline: 0;
  font-weight: 400;
  span {
    font-size: 22px;
  }
}

.buttons {
  padding: 0 12px 12px;
  a:last-child {
    margin-top: 12px;
  }
}

.userName {
  .roboto();
  font-size: 24px;
  font-weight: 700;
  line-height: 1em;
  margin-bottom: 7px;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.profileHeader {
  background: #104051;
  border-radius: 8px;
  margin-bottom: 16px;
}

.profileChip {
  display: flex;
  gap: 12px;
  padding: 12px;
}

.userDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.userRoleLine {
  display: flex;
  gap: 5px;
  align-items: center;
}

.userRole {
  .roboto();
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.profileActionsContainer {
  background: #104051;
  border-radius: 8px;
  margin-top: auto;

  a {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-block: 8px;
    padding-inline: 12px;
    @media @sm {
      padding-block: 27.75px;
      font-size: 16px;
    }
  }

  a:not(:last-child) {
    border-bottom: 1px solid #4a7381;
  }

  .actionNameIcon {
    color: #fff;
    display: flex;
    align-items: center;
    text-align: left;
    gap: 10px;

    span {
      color: #96b0b9;
      font-size: 16px;
      @media @sm {
        font-size: 20px;
      }
    }
  }
}

.actionAndNumber {
  display: flex;
  gap: 5px;
  span:last-of-type {
    color: #96b0b9;
  }
}

.heroWrapper {
  border-radius: 8px;
}

.mainContentWrapper__closed {
  height: 0 !important;
}

.loading {
  position: absolute;
  top: 30%;

  @media @sm {
    top: 150px;
  }
}

.mapIcon {
  margin-right: -2px;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;