@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';
@import (reference) '../../components/omnia_button/omnia_button.less';

.mainCTASection {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px;
  padding-top: 20px;
  @media @sm {
    grid-gap: 24px;
    padding-top: 40px;
  }
}

.mainCTASection__loggedIn {
  grid-template-rows: repeat(2, 1fr);
  @media @sm {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: unset;
    grid-gap: 24px;
    padding-top: 40px;
  }
}

.mainCTASection__loggedIn > :first-child {
  grid-column: 1 / -1;
  @media @sm {
    grid-column: unset;
  }
}

.cta {
  background: url('homepage_topo_cta_background_mobile.svg') no-repeat;
  background-size: 727px 140px;
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: min(100%, 644px);
  color: #fff;
  padding-block: 25px;
  @media @sm {
    padding-block: 24px;
    background: url('homepage_topo_cta_background.svg') no-repeat;
    background-size: 644px 124px;
    height: 124px;
  }

  svg {
    width: 14px;
    width: 14px;
    @media @sm {
      width: 16px;
      height: 16px;
    }
  }
  a {
    font-size: 13px;
    font-weight: 400;
    padding: 4px 8px;
    @media @sm {
      font-size: 14px;
      padding: 4px 15px;
    }
  }
}

.cta__map {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: min(100%, 644px);
  overflow: hidden;
  .textColor(#fff);
  background: url('shop_by_lake.jpg') no-repeat;
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;
  background-size: 936px 363px;
  background-position: 37% -153px;

  @media @sm {
    background-size: 936px 363px;
    background-position: 37% -153px;
  }
}

.ctaTitle {
  .termina(300);
  line-height: 1em;
  letter-spacing: 0.96px;
  margin-bottom: 8px;
  text-transform: uppercase;
  font-size: clamp(16px, 2.5vw, 24px);
  text-align: center;
  .textColor(#fff);
  @media @sm {
    line-height: 1.3em;
    text-align: left;
  }
}

.linkButton {
  .button();
  .primary();
  .medium();
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;