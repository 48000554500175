@import (reference) '../../styles/media.less';

.wrapper {
  position: relative;
}

.loading {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
}

.list {
  list-style: none;
  margin-inline: -12px;
  padding: 0;
}
.item {
  width: 47%;
  padding-inline: 12px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;

  @media @sm {
    width: max(16.6%, 223px);
  }
}

.h3 {
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: 0.84px;

  @media @sm {
    font-size: 24px;
    letter-spacing: 1.12px;
  }
}

.sectionHeader__new {
  display: flex;
  align-items: baseline;
  h3 {
    margin-bottom: 14px;
    @media @sm {
      margin-bottom: 24px;
    }
  }
  padding-top: 30px;
  @media @sm {
    padding-top: 50px;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;