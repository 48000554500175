@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';

.wrapper {
  display: flex;
  flex-direction: column;
  padding-block: 50px;

  @media @sm {
    flex-direction: row;
    padding-block: 50px 75px;
  }

  > div {
    flex: 1 1;
  }

  p {
    font-size: 16px;
    margin-bottom: 1em;
  }
}

.headline {
  .termina();
  font-size: 18px;
  line-height: 1.33;
  margin-bottom: 24px;

  @media @sm {
    font-size: 24px;
  }
}
.img {
  margin-bottom: 24px;

  @media @sm {
    margin-bottom: 0;
  }
}

.button {
  margin-bottom: 24px;
}

.download {
  font-weight: 500;
  font-size: 18px;
}

.appStores {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  gap: 6px;

  li {
    transition: all ease-in-out 0.3s;
    padding-bottom: 8px; // i don't know why this is needed, but the svgs cut off without it
    &:hover {
      transform: scale(1.05);
    }
  }
}

.appleStoreIcon {
  @media @sm {
    max-width: 200px;
  }
}
.googleplayIcon {
  @media @sm {
    max-width: 200px;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;