.termina(@weight: 500) {
  font-family: termina, sans-serif;
  font-weight: @weight;
}
.roboto(@weight: 400) {
  font-family: 'Roboto', sans-serif;
  font-weight: @weight;
}

.h1() {
  .termina(500);
  font-size: 55px;
  letter-spacing: 2.57px;
  line-height: 66px;
}
.h2() {
  .termina(500);
  font-size: 42px;
  letter-spacing: 1.96px;
  line-height: 50px;
}
.h3 {
  .termina(500);
  font-size: 24px;
  letter-spacing: 1.12px;
  line-height: 24px;
}
.h4 {
  .termina(500);
  font-size: 18px;
  letter-spacing: 0.84px;
  line-height: 24px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  .termina();
  font-weight: 500;
  color: currentColor;
  line-height: 1.2;
}

.textColor(@hex) {
  color: @hex;
  &:hover {
    color: @hex;
  }
  &:focus {
    color: @hex;
  }
  &:active {
    color: @hex;
  }
}
.textUnderline {
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    text-decoration: underline;
  }
  &:active {
    text-decoration: underline;
  }
}

.orangeText() {
  .textColor(#e84b25);
}

.antialias() {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.landingPageH1() {
  .termina(500);
  color: #fff;
  font-size: clamp(30px, 4vw, 38px);
  line-height: 38px;

  @media @sm {
    line-height: 46px;
  }
}

.landingPageH2() {
  .roboto(500);
  font-size: 28px;
  line-height: 38px;
  letter-spacing: normal;
}

.landingPageP() {
  .roboto();
  font-size: 16px;
  line-height: 28px;
}

.typography(@font-size: 16px, @line-height: 1em, @color: currentColor, @margin-bottom: 1em, @font-weight: 400) {
  font-size: @font-size;
  line-height: @line-height;
  font-weight: @font-weight;
  color: @color;
  margin-bottom: @margin-bottom;
}

.fluid-type(@min-size, @max-size) {
  font-size: @min-size;

  @media @sm {
    font-size: calc(
      @min-size ~' + ' unit(@max-size - @min-size) ~' * ((100vw - ' @screen-sm ~') /' unit(
          @screen-lg - @screen-sm
        ) ~')'
    );
  }
  @media @lg {
    font-size: @max-size;
  }
}
