@import (reference) '../../styles/media.less';

.wrapper {
  position: relative;
}
.loading {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
}

.list {
  list-style: none;
  margin: 0 -12px;
  padding: 0;
}
.item {
  width: 42%;
  padding: 0 12px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;

  @media @sm {
    width: max(16.6%, 223px);
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;