@import (reference) '../../styles/media.less';
@import (reference) '../../styles/typography.less';

.product {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding-bottom: 3px; // for edge of button
}
.link {
  margin-bottom: 12px;
}

.imgWrapper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-bottom: 12px;
  display: block;
  max-width: 100%;
}

.productImg {
  background-color: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.badge {
  position: absolute;
  right: 4px;
  bottom: 4px;
  .roboto(600);
  color: #fff;
  .antialias();
  font-size: 12px;
  line-height: 1;
  padding: 2px 4px;
  border-radius: 4px;
}

.badge__bestSeller {
  background-color: #308800;
}
.badge__onSale {
  background-color: #ff9948;
}
.badge__new {
  background-color: #00388c;
}

.icon {
  position: absolute;
  top: 0;
  left: 0;
}

.brand {
  .roboto();
  color: #657d7d;
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 6px;
  .antialias();
}
.title {
  .roboto(500);
  color: #000;
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 6px;
  word-break: break-word;
  .antialias();
}

.reportedOnLake {
  display: flex;
  align-items: center;
  gap: 3px;
  line-height: 1.2;
  margin-bottom: 0;
  cursor: pointer;
  .textColor(#000);
  font-size: 14px;

  em {
    text-decoration: underline;
    font-style: normal;
  }
}

.breakWord {
  overflow-wrap: break-word;
}

.inlineIcon {
  padding-top: 1px;
  min-width: 16px;
}

.fishingReportIcon {
  min-width: 16px;
}

.bottom {
  margin-top: auto;
  padding-top: 12px;
}

.price {
  .termina();
  color: #657d7d;
  font-size: 14px;
  font-weight: 500;
  line-height: 12px;
  margin-bottom: 1em;
  .antialias();

  a {
    display: flex;
    flex-wrap: wrap;
    gap: 0.3em;
  }
}
.priceCompare {
  opacity: 0.5;
  white-space: nowrap;
}
.price__sale {
  color: #d8363e;
}

.cartIcon.cartIcon {
  margin-right: 2px;
  font-size: 17px;
  width: 17px;
}

.mailIcon.mailIcon {
  font-size: 16px;
  width: 16px;
  margin-right: 3px;
}

.productButtons {
  padding-block: 6px;
}

.badge__reportedDate {
  .roboto(500);
  .antialias();
  line-height: 1;

  position: absolute;
  background: rgba(230, 230, 230, 1);
  font-size: 12px;
  top: 1px;
  left: 1px;
  color: rgba(0, 0, 0, 0.7);
  height: min-content;
  padding: 5px 10px 5px 5px;
  border-radius: 8px 0 0 0;
  clip-path: polygon(0 0, 100% 0, calc(100% - 10px) 100%, 0% 100%);
}

.reportButton {
  display: flex;
  align-items: center;
  padding-block: 6px;
  margin-bottom: 6px;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;