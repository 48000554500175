@import (reference) '../../styles/colors.less';
@import (reference) '../../styles/typography.less';

.link {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.img {
  max-width: 134px;
}
.name {
  .termina();
  color: #000;
  font-size: 12px;

  &:hover {
    color: #000;
  }
}
.role {
  margin: 10px 0;
  border-top: 2px solid transparent;
  width: 35%;

  &.bronze {
    border-top-color: @ambassador_bronze;
  }

  &.silver {
    border-top-color: @ambassador_silver;
  }

  &.gold {
    border-top-color: @ambassador_gold;
  }

  &.blue {
    border-top-color: @ambassador_blue;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;