@import (reference) '../../styles/typography.less';

.badge {
  font-size: 12px;
  .roboto(600);
  color: #fff;
  .antialias();
  display: flex;
  gap: 2px;
  align-items: center;
  padding: 0px 14px 0px 4px;
  clip-path: polygon(0 0, 100% 0, calc(100% - 10px) 100%, 0% 100%);
}
.badge__hotbait {
  background-color: #fb2422;
}

.badge__nearYou {
  background-color: #0082d5;
}

.icon {
  color: #fff;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;