@import (reference) '../../styles/colors.less';
@import (reference) '../../styles/media.less';
@import (reference) '../../styles/mixins.less';
@import (reference) '../../styles/typography.less';

.videoSection {
  display: flex;
  flex-direction: column;
  padding-top: 50px;

  @media @sm {
    margin-left: -6px;
    margin-right: -6px;
    flex-direction: row;
  }
}
.video {
  @media @sm {
    margin-bottom: 0;
    padding: 0 6px;
  }
}
.videoLink,
.subheroLink {
  display: block;
  margin-bottom: 12px;
}

.subheroSection {
  display: flex;
  flex-wrap: wrap;
  margin-left: -6px;
  margin-right: -6px;
  padding-bottom: 50px;
}

.subheroMain {
  padding: 0 6px;

  @media @sm {
    width: 66.66%;
  }
}
.subheroSide {
  padding: 0 6px;

  @media @sm {
    width: 33.33%;
  }
}

.hiddenText {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.heroWrapper__loggedIn {
  text-align: center;
  padding-block: 10px;
  color: #fff;
  background: @omnia_blue_primary;
  @media @sm {
    height: auto;
    margin-bottom: 0;
    padding-block: 30px 10px;
  }
}

.subtagline {
  .termina(500);
  font-size: 18px;
  line-height: 22px;
  max-width: 860px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.h2 {
  .roboto();
  color: #fff;
  font-weight: 300;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.65px;
  margin-bottom: 0.5em;

  @media @sm {
    font-size: 13px;
  }
}
.h3 {
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: 0.84px;
  margin-bottom: 0.5em;

  @media @sm {
    font-size: 24px;
    letter-spacing: 1.12px;
  }
}
.h4 {
  font-size: 15px;
  letter-spacing: 0.7px;
  line-height: 1.33;
  margin-bottom: 0.5em;

  @media @sm {
    font-size: 18px;
    letter-spacing: 0.84px;
  }
}

.articlesSection {
  padding: 50px 0 0;
}

.articles {
  list-style: none;
  margin: 0 -12px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.article {
  margin-bottom: 24px;
  padding: 0 12px;

  @media @sm {
    width: 25%;
  }
}

.sectionHeader {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  margin-bottom: 0;

  > :first-child {
    margin-right: 30px;
  }
  a {
    color: #000;
    &:hover {
      color: #000;
    }
  }
}

.sectionSeeAll.sectionSeeAll {
  .termina();
  font-size: 12px;
  .orangeText();

  & + & {
    margin-left: 2em;
  }
}
.sectionHeaderRight {
  .termina();
  align-self: center;
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 36px;
  margin-top: 15px;
  flex-direction: column;
  gap: 13px;
  margin-right: auto;

  @media only screen and (min-width: 420px) {
    margin-right: unset;
    flex-direction: row;
  }
  @media @sm {
    margin-top: 0;
  }
}

.popularLakeSection,
.popularSection {
  padding-top: 30px;
  @media @sm {
    padding-top: 50px;
  }
}

.popularSection {
  .sectionHeader {
    flex-direction: column;
    flex-wrap: nowrap;
    margin-bottom: 14px;
    @media @sm {
      margin-bottom: 24px;
    }
  }
}

.sectionHeader__new {
  h3 {
    margin-bottom: 14px;
    @media @sm {
      margin-bottom: 24px;
    }
  }
  padding-top: 30px;
  @media @sm {
    padding-top: 50px;
  }
}

.sectionHeader__yourLakes {
  .sectionHeader__new();

  @media @sm {
    padding-top: 70px;
  }
}
.sectionHeader__recentMedia {
  padding-top: 50px;
  @media @sm {
    padding-top: 50px;
  }
  h3 {
    margin-bottom: 0;
    @media @sm {
      margin-bottom: 14px;
    }
  }
  @media @sm {
    padding-top: 70px;
  }
}

.forYouWrapper {
  margin-bottom: 20px;
  @media @sm {
    margin-bottom: 0;
  }
}

.ambassadorSection {
  padding: 50px 0;
}
.ambassadorList {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  row-gap: 24px;
  margin-left: -12px;
  margin-right: -12px;
  overflow-y: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  @media @sm {
    flex-wrap: wrap;
  }
}
.ambassadorListItem {
  width: 60%;
  padding: 0 12px;
  flex: 0 0 auto;

  @media @sm {
    width: 16.66%;
  }
}

.lakesAndFeatured {
  display: flex;
  flex-direction: column;

  @media @sm {
    flex-direction: row;
  }
}

.featuredProducts {
  padding: 50px 0;
}

.video {
  width: 100%;
  margin-bottom: 24px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.mapSection {
  padding: 50px 0;
}
.map {
  width: 100%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;

  .us-state-map {
    width: 100%;
  }
  path:hover,
  circle:hover {
    cursor: pointer;
    fill: #006b99;
  }
}
.mapSelect {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: 24px;
  margin-top: 24px;
  :global {
    .ant-select-selection-search-input {
      font-size: 16px;
    }
  }
}

.viewMapLink {
  .termina();
  font-size: 12px;
  .orangeText() !important;
}

.sectionHeaderMap {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;

  > :first-child {
    margin-right: 30px;
  }
  a {
    color: #000;
    &:hover {
      color: #000;
    }
  }
}

.mapSubheaderText {
  font-size: 14px;
  color: #aaa;
  margin-bottom: 20px;
}

.subheaderButton {
  appearance: none;
  border: 0;
  display: flex;
  background: none;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}
.subheaderSpeciesImg {
  width: 60px;
  margin-right: 6px;
}
.subheaderArrow {
  margin-left: 6px;
  flex: 0 0 auto;
}

.seasonSelectWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.seasonSelectIcon {
  font-size: 24px;
  flex: 0 0 auto;
}
.seasonSelect {
  :global {
    .ant-select-arrow {
      color: #000;
      font-size: 14px;
      width: 16px;
      height: 16px;
      margin-top: -8px;
      padding-left: 2px;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: 32px !important;
    }
  }
}

.videosBackground {
  background-color: #002b3a;
  color: #fff;
  padding-bottom: 1px;
}

/* safari mobile needs to set height on svgs to 0 */
.backgroundSVGs {
  svg {
    height: 0;
  }
}
.svgWave {
  background: white;
  height: 100px;
  position: relative;
  z-index: 1;
  top: -1px;
  margin-bottom: -44px; // accounting for dead space in the svg
  margin-top: -6px; // accounting for dead space in the svg
  clip-path: url(#clipTwo);
  @media @md {
    clip-path: url(#clip);
  }
}

.svgWave__bottom {
  background-color: #002b3a;
}

.statesMapSection {
  @media @sm {
    margin-top: 10px;
  }
}

.statesMapContainer {
  @media @sm {
    margin-top: -20px;
  }
}

.seasonSpeciesWrapper {
  display: flex;
  justify-content: center;
  @media @sm {
    justify-content: left;
  }
}

.seasonSpeciesLine {
  display: flex;
  align-items: baseline;
  gap: 8px;
  & > span {
    .termina(500);
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: 0.84px;
  }
}

.waterbodyLink {
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
}

.dashboardLoading {
  height: 517px;
}

.recentProductReportCard {
  width: max(16.6%, 223px);
  @media @sm {
    width: '47%';
  }
}

.recentMediaReportCard {
  width: min(90%, 334px);
  padding-inline: 12px;
  height: 382px;
  @media @sm {
    width: 334px;
  }

  img {
    z-index: -1;
  }
}

.videoText {
  text-shadow: 0px 4px 4px #00000040;
}

.autoplayVideo {
  aspect-ratio: 656 / 1532;
  @media @sm {
    aspect-ratio: 1300 / 566;
  }

  :global {
    .plyr--video {
      aspect-ratio: 656 / 1532;

      @media @sm {
        aspect-ratio: 1300 / 566;
      }
    }
    .plyr video {
      max-height: unset;
    }
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;