@import (reference) '../../../../styles/media.less';
@import (reference) '../../../../styles/typography.less';
@import (reference) '../../../../styles/colors.less';

.hero {
  color: #fff;
}

.bg {
  background-color: #344853;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('./v1-bg-mobile.jpg');
  background-position: center top;
  display: flex;
  align-items: center;
  min-height: 505px;

  @media @sm {
    background-image: url('./v1-bg.jpg');
    background-position: 20% top;
  }

  @media @md {
    background-position: 30% top;
  }

  @media @lg {
    background-position: center top;
  }
}

.top {
  @media @sm {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.h1 {
  .termina(300);
  .fluid-type(34px, 48px);
  .antialias();
  line-height: 1.08;
  color: #fff;
  width: 77%;
  margin-bottom: 36px;

  @media @sm {
    margin-bottom: 1em;
    width: 100%;
    max-width: 800px;
    text-align: center;
  }
}

.bottomLine {
  display: block;
  margin-top: 36px;

  a {
    color: #fff;
    padding-left: 4px;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.waterbodySelectWrapper {
  :global {
    .ant-select {
      display: flex;
      @media @sm {
        width: clamp(300px, 60vw, 750px);
      }
    }

    .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-radius: 10px 0 0 10px;
      height: 54px;
    }

    .ant-select:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-search-input {
      border-radius: 10px 0 0 10px;
      height: 54px;
      font-size: clamp(16px, 4vw, 18px);
      line-height: 1.2;
      padding-left: 10px;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
      font-size: clamp(16px, 4vw, 18px);
      line-height: 1.2;
      height: 54px;
      display: flex;
      align-items: center;
      padding-left: 10px;
      color: #06162298;
    }

    .ant-select-arrow {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: @omnia_orange_primary;
      height: 54px;
      width: 54px;
      margin-top: 0;
      border-radius: 0 10px 10px 0;
      right: 0;

      svg {
        font-size: clamp(16px, 4vw, 18px);
        color: #fff;
      }
    }
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;