@import (reference) '../../styles/mixins.less';
@import (reference) '../../styles/typography.less';
@import (reference) '../../styles/colors.less';

// needs to be more specific than base classes
.roboto.roboto {
  .roboto();
}

.orange.orange {
  .antButtonOrange();
}

// New Omnia Button :shiny:

// @media (hover: hover) is used to prevent hovers on mobile devices
// a& specific styles are needed to override the default styles for links

// Initial a&:hover text color should match the base style for after click mobile styling
// .tertiary {
//   color: #595959;
//   ...
//
//   a&:hover {
//     color: #595959;
//   }

.button {
  flex: 0 0 auto;
  .roboto(500);
  line-height: 1.5;
  display: inline-flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  transition: background-color 100ms ease-out, color 100ms ease-out, border-color 100ms ease-out,
    box-shadow 100ms ease-out;

  @media @sm {
    transition: background-color 300ms ease-out, color 300ms ease-out, border-color 300ms ease-out,
      box-shadow 300ms ease-out;
  }

  &[aria-disabled='true'],
  &[disabled] {
    background-color: #f5f5f5 !important;
    border: 1px solid #d9d9d9 !important;
    color: rgba(0, 0, 0, 0.25) !important;
    box-shadow: none !important;
    cursor: not-allowed;

    &.pressed {
      pointer-events: none;
    }
  }

  &:focus-visible {
    outline: none;
  }
}

.primary {
  color: #fff;
  background-color: @omnia_orange_primary;
  border: 1px solid @omnia_orange_primary;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);

  a&:hover {
    color: #fff;
  }
  @media (hover: hover) {
    &:hover,
    a&:hover {
      color: #fff;
      background-color: #f5754e;
      border: 1px solid #f5754e;
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
    }
  }
  &:active,
  a&.active {
    color: #fff;
  }
  &.pressed,
  a&.pressed {
    color: #fff;
    background-color: #c23215;
    border: 1px solid #c23215;
    box-shadow: none;
  }
}

.secondary {
  color: #fff;
  background-color: #006b99;
  border: 1px solid #006b99;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);

  a&:hover {
    color: #fff;
  }
  @media (hover: hover) {
    &:hover,
    a&:hover {
      color: #fff;
      background-color: #1b81a6;
      border: 1px solid #1b81a6;
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
    }
  }
  &:active,
  a&.active {
    color: #fff;
  }
  &.pressed,
  a&.pressed {
    color: #fff;
    background-color: #004c73;
    border: 1px solid #004c73;
    box-shadow: none;
  }
}

.tertiary {
  background-color: #fff;
  color: #595959;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 1px 4px 0px #0000001f;

  a&:hover {
    color: #595959;
  }
  @media (hover: hover) {
    &:hover,
    a&:hover {
      color: rgba(0, 0, 0, 0.85);
      border: 1px solid #f0f0f0;
      box-shadow: 0px 2px 4px -1px #00000033;
    }
  }

  &:active,
  a&.active {
    color: #595959;
  }
  &.pressed,
  a&.pressed {
    color: #595959;
    border: 1px solid #bfbfbf;
    box-shadow: none;
  }
}

.text {
  color: #006b99;
  border: 1px solid transparent;
  background-color: transparent;

  a&:hover {
    color: #006b99;
  }
  @media (hover: hover) {
    &:hover,
    a&:hover {
      color: #1b81a6;
    }
  }
  &:active,
  a&.active {
    color: #004c73;
  }
  &.pressed,
  a&.pressed {
    color: #004c73;
  }

  &[aria-disabled='true'],
  &[disabled] {
    border-color: transparent !important;
    background-color: transparent !important;
  }
}

.link {
  color: #006b99;
  text-decoration: underline;
  background-color: transparent;
  border: 1px solid transparent;

  a&:hover {
    color: #1b81a6;
  }
  @media (hover: hover) {
    &:hover,
    a&:hover {
      color: #1b81a6;
      background-color: #fafafa;
      border: 1px solid #fafafa;
      text-decoration: underline;
    }
  }
  &:focus {
    text-decoration: underline;
  }

  &:active,
  a&.active {
    color: #004c73;
    text-decoration: underline;
  }
  &.pressed,
  a&.pressed {
    color: #004c73;
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
    text-decoration: underline;
  }

  &[aria-disabled='true'],
  &[disabled] {
    border-color: transparent !important;
    background-color: transparent !important;
  }
}

.small {
  padding: 0 7px;
  font-size: 14px;
}

.medium {
  padding: 4px 15px;
  font-size: 14px;
}

.large {
  padding: 9px 15px;
  font-size: 16px;
}

.xlarge {
  padding: 13px 15px;
  font-size: 16px;
}

.block {
  display: flex;
  width: 100%;
}

.square {
  aspect-ratio: 1;
  padding: 0;

  &.small {
    height: 24px;
    width: 24px;
  }

  &.medium {
    height: 32px;
    width: 32px;
  }

  &.large {
    height: 44px;
    width: 44px;
  }

  &.xlarge {
    height: 52px;
    width: 52px;
  }
}

.circle {
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 0;

  &.small {
    height: 24px;
    width: 24px;
  }

  &.medium {
    height: 32px;
    width: 32px;
  }

  &.large {
    height: 44px;
    width: 44px;
  }

  &.xlarge {
    height: 52px;
    width: 52px;
  }
}
