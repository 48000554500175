@import (reference) '../../styles/media.less';

.videos {
  padding: 24px 0 48px 0;

  h3 {
    font-size: 24px;
    margin-bottom: 24px;
  }

  h4 {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 0.5em;
  }

  p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 1em;
  }
}

.videoCols {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media @sm {
    flex-direction: row;
  }

  > div {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
.videoPlayer {
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 24px;
  width: 100%;
}

.videosButton {
  margin-top: auto;
}

.placeHolderImg {
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 24px;
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;