@import (reference) '../../styles/media.less';

.promoSection {
  display: flex;
  flex-direction: column;
  padding-top: 50px;

  @media @sm {
    margin-left: -6px;
    margin-right: -6px;
    flex-direction: row;
  }
}
.promoItem {
  @media @sm {
    margin-bottom: 0;
    padding: 0 6px;
  }
}
.promoLink {
  display: block;
  margin-bottom: 12px;
}

.hiddenText {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.promoSection__subHero {
  display: flex;
  flex-wrap: wrap;
  margin-left: -6px;
  margin-right: -6px;
  padding-bottom: 50px;
}

.promoItem__subHero__big {
  padding: 0 6px;

  @media @sm {
    width: 66.66%;
  }
}
.promoItem__subHero__small {
  padding: 0 6px;

  @media @sm {
    width: 33.33%;
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;